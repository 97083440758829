/*-----------------------------------------------
|   Browser Fixing
-----------------------------------------------*/
// Firefox fixed
.firefox{
  .dropcap:first-letter{ margin-top: 0.175em; }
}


// Edge fixed
.edge{
  .bg-holder{
    .bg-video{
      position: relative !important;
      height: 100%;
      width: auto;
    }
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .align-items-center.minh-100vh{ height: 100vh; }
  .align-items-end.minh-100vh{ height: 100vh; }
  img{ max-width: 100%; }
  .card-img-top{ overflow: hidden; }
  .row.min-vh-100{ height: 100vh; }
  .row.min-vh-75{ height: 75vh; }
  .row.min-vh-50{ height: 50vh; }
  .card-body{
    overflow: hidden;
  }
  .bg-holder .bg-video { position: relative !important; }
}