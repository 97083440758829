/*-----------------------------------------------
|   Flexslider
-----------------------------------------------*/

.flex-direction-nav a{
  color: transparent;
  text-shadow: none;

  &:before {
    color: transparent;
    text-shadow: none;
  }
}