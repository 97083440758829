/*-----------------------------------------------
|   Pricing card text color
-----------------------------------------------*/
.text-white-30{ color: rgba($white, 0.30); }
.text-white-70{ color: rgba($white, 0.70); }
.text-white-50{ color: rgba($white, 0.50); }
.text-white-90 { color: rgba($white, 0.75); }

/*-----------------------------------------------
|   Pricing card border
-----------------------------------------------*/
.border-white-20{ border: 1px solid rgba($white,0.20); }
.border-wb-20{ border-bottom:  1px solid rgba($white, 0.20); }

