/*-----------------------------------------------
|   Blockquote
-----------------------------------------------*/
.blockquote{ line-height: 1.875rem; }

.blockquote-content{
  font-style: italic;
  position: relative;
  &:before{
    position: absolute;
    left: -3rem;
    top: -0.5rem;
    line-height: 1;
    content: quote('\201C');
    display: inline-block;
    color: $gray-300;
    font-size: map_get($font-sizes, 6);
  }
}

.blockquote-footer{
  &::before {
    transform: translateY(-0.09rem);
    font-weight: $font-weight-normal;
    display: inline-block;
  }
}