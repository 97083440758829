/*-----------------------------------------------
|   Navigation Top
-----------------------------------------------*/
.dropdown-item{
  font-weight: $font-weight-medium;
  border-bottom: 1px solid rgba(73,71,71,.15);
  letter-spacing: .125rem;
  padding: .8rem 1.2rem;
  @include hover-focus{
    background-color: initial;
    color: $white;

  }
}
.dropdown-menu{
  font-size: 12px;
  overflow: hidden;
  box-shadow: $box-shadow-sm;
  border-radius: $border-radius-soft;
}
.dropdown-menu-card{
  padding: 0;
  .card{ border: 0}
}

.navbar{
  .dropdown-menu {
    overflow: visible;
    left: -0.5625rem;
    &:after {
      content: '';
      position: absolute;
      z-index: -1;
      width: 1rem;
      height: 1rem;
      background: $black;
      top: -0.375rem;
      left: map_get($spacers, 4);
      transform: rotate(45deg);
      background: inherit;
      border-radius: 0.125rem;
      border-width: 1px 0 0 1px;
      border-style: solid;
      border-color: $dropdown-border-color;
      box-shadow: $box-shadow-sm;
    }
    &.dropdown-menu-right {
      left: auto;
      right: -0.5625rem;
      &:after {
        right: 0.95rem;
        left: auto;
      }
      
    }
  }
}
.navbar-toggler{
  border: 0;
  padding: 0;
}
