/*-----------------------------------------------
|   Buttons
-----------------------------------------------*/
.btn{ text-transform: uppercase; }
.btn-primary, .btn-secondary, .btn-success, .btn-danger, .btn-warning, .btn-info, .btn-dark {
  border-color: transparent;
  box-shadow: 0 2px 5px 0 rgba(43,45,80, 0.08), 0 1px 1.5px 0 rgba($black, 0.07), 0 1px 2px 0 rgba($black, 0.08);
  @include hover-focus{
    box-shadow: 0 2px 5px 0 rgba(43,45,80, 0.1), 0 3px 9px 0 rgba(43,45,80, 0.08), 0 1px 1.5px 0 rgba($black, 0.08), 0 1px 2px 0 rgba($black, 0.08);
  }
  &:active{ box-shadow: none; }
}

.btn-light{
  @include button-variant($white, $white, $white, $white);
  @include hover-focus-active{ color: $dark !important; }
  box-shadow: 0 0 0 1px rgba(43,45,80, 0.1), 0 2px 5px 0 rgba(43,45,80, 0.08), 0 1px 1.5px 0 rgba($black, 0.07), 0 1px 2px 0 rgba($black, 0.08);
  @include hover-focus{
    box-shadow: 0 0 0 1px rgba(43,45,80, 0.1), 0 2px 5px 0 rgba(43,45,80, 0.1), 0 3px 9px 0 rgba(43,45,80, 0.08), 0 1px 1.5px 0 rgba($black, 0.08), 0 1px 2px 0 rgba($black, 0.08);
  }
  &:active{ box-shadow: none; }
  &:disabled,
  &.disabled {
    color: $btn-link-disabled-color;
    @include hover-focus-active{ color: $btn-link-disabled-color !important; }
  }
}
.btn:disabled,
.btn.disabled {
  box-shadow: none;
}
