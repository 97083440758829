/*-----------------------------------------------
|   Dropdowns
-----------------------------------------------*/
.dropdown-menu{
  box-shadow: $box-shadow;
  background-color: rgba(#000, 0.90 );
  border: 0;
  top: 74%;

  padding-bottom: 0 ;
 
}
.dropdown-item{
  transition: $transition-base;
  padding-top: map_get($spacers, 1.5);
  padding-bottom: map_get($spacers, 1.5);
  @include hover{
    background-color: #000 !important;
    
  }
}

.dropdown-caret-none::after{
  display: none;
}
